<template>
  <div class="page project-config">
    <b-container>
      <b-row>
        <b-col cols="6" offset="3">
          <div class="panel">
            <k-layout-config-form></k-layout-config-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'layout-config-page',
  data() {
    return {};
  },
  mounted() {},
  created() {},

  computed: {},
  watch: {},
};
</script>
